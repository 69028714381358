import React from "react";

export default function FilterSidebar(props) {


  return (
    <div class="sidebar-widget mt-40 sidebar-border pb-45">
      <h4 class="pro-sidebar-title">
        <span>Filter by </span>
        {props.title}
      </h4>
      {props.children}
    </div>
  );
}
