import React, {useState, useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Flex, Box, Heading, Text } from 'rebass';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import GatsbyLink from 'gatsby-link';
import Img from "gatsby-image"




function CategoriesPageSubcategories({ items }) {

  const itemsMain = items.filter(item => item.node.nodeSupporting !== 1);

  const itemsSupporting = items.filter(item => item.node.nodeSupporting === 1);

if(items && items.length > 0){
  return (
<>
<h2 class="title ccnCollectionTitle">Products</h2>
<div class="ccnCollectionItems">
<div class="row">
{itemsMain.map(
  (item, index) => {

    let {
      nodeName,
      nodeDesc,
      fileNodeImage,
      nodeImage,
      pageUrl
    } = item.node

    let itemDesc = '';
    if(item.node.nodeDesc !== null) {
      itemDesc = item.node.nodeDesc.replace(/(<([^>]+)>)/gi, "")
      itemDesc = itemDesc.length > 100 ? `${itemDesc.substring(0, 100)}...` : itemDesc
      itemDesc = ReactHtmlParser(itemDesc)
    }

    let itemImage = item.node.nodeImage

    if(fileNodeImage) itemImage = fileNodeImage.childImageSharp.fluid

    let alias = item.node.pageUrl
    if(
      alias !== null
      && alias.includes('-01')
    ) {
      alias = alias.substring(0, alias.indexOf('-01'));
    }
    if(
      alias !== null
      && alias.includes('-0')
    ) {
      alias = alias.substring(0, alias.indexOf('-0'));
    }
    if(alias === '/products/vw-vwb') {
      alias = '/products/vw_vwb'
    }

    return (
      <>
      <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
    <div class="single-content-banner ccnCard catalog-box mb-20">
        <div class="banner-images">
        <GatsbyLink to={alias}>
          {fileNodeImage && <Img fluid={itemImage} alt={item.node.nodeName} />}
          {!fileNodeImage && <img src={itemImage} alt={item.node.nodeName} />}
        </GatsbyLink>
        </div>
        <div class="--banner-content full-width">
            <h3 class="title">{item.node.nodeName}</h3>
            {itemDesc && <p>{itemDesc}</p>}
            <GatsbyLink
              to={alias} className={"catExporeMore go go-Arimo"}>Explore more</GatsbyLink>
        </div>
    </div>
</div>




      </>
    )
  }
)}
</div>
</div>
{itemsSupporting.length > 0 &&
  <>
  <h2 class="title ccnCollectionTitle">Supporting Parts</h2>
  <div class="ccnCollectionItems">
  <div class="row">


  {itemsSupporting.map(
    (item, index) => {

      // const { node } = item

      let {
        nodeName,
        nodeDesc,
        nodeImage,
        fileNodeImage,
        pageUrl
      } = item.node

      if(nodeDesc !== null) {
        nodeDesc = nodeDesc.replace(/(<([^>]+)>)/gi, "")
        nodeDesc = nodeDesc.length > 100 ? `${nodeDesc.substring(0, 100)}...` : nodeDesc
        nodeDesc = ReactHtmlParser(nodeDesc)
      }

      return (
          <>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
        <div class="single-content-banner ccnCard catalog-box supporting mb-20">
            <div class="banner-images">
            <GatsbyLink
              to={pageUrl}>
              {fileNodeImage && <Img fluid={fileNodeImage.childImageSharp.fluid} alt={nodeName} />}
              </GatsbyLink>
            </div>
            <div class="--banner-content full-width">
                <h3 class="title">{nodeName}</h3>
                <p>{nodeDesc}</p>
                <GatsbyLink
                  to={pageUrl} className={"catExporeMore go go-Arimo"}>Explore more</GatsbyLink>
            </div>
        </div>
    </div>

          </>
        )
    }
  )}


  </div>
  </div>
  </>
}

</>
  )
}
return null;
}


export default CategoriesPageSubcategories;
