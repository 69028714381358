import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import { nest } from '../../helpers/ContentHelpers'
import GatsbyLink from 'gatsby-link';

export default function ListAccordion({ data, id }){

  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // alert(id)
  let dataId = null;
  if(typeof id != "undefined") {
    dataId = id;
  }
  let nestData = nest(data, dataId);


  // console.log(nestData)
  // console.log("nestData")
  // console.log(data)
  // console.log("data")

  return (
    <>
      <div className="ccnListAccordion">
      {nestData.map((item, key) => {
        let panelId = 'panel' + key;
        const {
          pageUrl,
          nodeName
        } = item
        return (
          <Accordion expanded={expanded === panelId} onChange={handleChange(panelId)} className="ccnListAccordionItem">
            <AccordionSummary
              expandIcon={<AddIcon />}
              aria-controls={panelId}
              id={panelId}
              className="ccnListAccordionItemS"
            >
              {nodeName} <span className="accentInfo">({item.children.length})</span>
            </AccordionSummary>
            <AccordionDetails className="ccnListAccordionItemD">
              <ul>
                {item.children.map(item => {

                  return (
                    <li><GatsbyLink to={item.pageUrl}>{item.nodeName} {/*<span className="accentInfo">(01)</span>*/}</GatsbyLink></li>
                  )

                })}
              </ul>
            </AccordionDetails>
          </Accordion>
        )
      })}
    </div>
  </>
  );
}
